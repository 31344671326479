import React, { ComponentProps, forwardRef } from 'react';
import { SessionSourceTypes } from 'models/session';
import { storeSessionSource } from 'utils/session-storage-source';

export type LinkWithSourceProps = ComponentProps<'a'> & {
  source: SessionSourceTypes;
};

const LinkWithSource: React.FC<LinkWithSourceProps> = forwardRef(({ source, onClick, ...rest }, ref) => {
  const _onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    storeSessionSource(source);
    onClick(event);
  };

  return <a ref={ref} onClick={_onClick} {...rest} />;
});

LinkWithSource.displayName = 'LinkWithSource';

export default LinkWithSource;
