import { ComponentProps } from 'react';
import { BreakpointShorthands } from 'assets/styles';
import { Container } from './section.style';

export type SectionProps = ComponentProps<'section'> & {
  isFirst?: boolean;
  hide?: BreakpointShorthands[];
};

const Section: React.FC<SectionProps> = ({ isFirst, hide = [], children }) => (
  <Container $hide={hide} $isFirst={isFirst}>
    {children}
  </Container>
);

export default Section;
