import React, { ComponentProps, useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { ChevronDown } from 'assets/icons';
import { Container, SliderContainer, Slider, Slide, PreviousButton, NextButton } from './carousel.style';

export type CarouselProps = ComponentProps<'ul'> & {
  hideArrows?: boolean;
  columns?: string;
  isDark?: boolean;
  hasContentCards?: boolean;
  onChangeScrollable?: (scrollable: boolean) => void;
};

const Carousel: React.FC<CarouselProps> = ({
  hideArrows,
  columns = 'mb:2 tb:3 tl:4 dt:5',
  isDark,
  hasContentCards,
  children,
  onChangeScrollable,
}) => {
  const slides = React.Children.toArray(children);
  const [showPrev, setShowPrev] = useState<boolean>(false);
  const [showNext, setShowNext] = useState<boolean>(true);
  const [ref, emblaApi] = useEmblaCarousel(
    {
      align: 'start',
      containScroll: 'trimSnaps',
      draggable: showNext || showPrev,
    },
    [WheelGesturesPlugin()],
  );

  const scrollPrevious = useCallback(() => emblaApi?.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    const checkPrevNext = () => {
      setShowPrev(emblaApi.canScrollPrev());
      setShowNext(emblaApi.canScrollNext());
      onChangeScrollable?.(emblaApi.canScrollPrev() || emblaApi.canScrollNext());
    };

    checkPrevNext();
    emblaApi.on('init', checkPrevNext);
    emblaApi.on('resize', checkPrevNext);
    emblaApi.on('select', checkPrevNext);

    return () => {
      emblaApi.off('init', checkPrevNext);
      emblaApi.off('resize', checkPrevNext);
      emblaApi.off('select', checkPrevNext);
    };
  }, [emblaApi, setShowPrev, setShowNext, onChangeScrollable]);

  return (
    <Container>
      <SliderContainer ref={ref}>
        <Slider>
          {slides.map((child, index) => (
            <Slide key={index} $columns={columns}>
              {child}
            </Slide>
          ))}
        </Slider>
      </SliderContainer>
      {!hideArrows && (
        <>
          {showPrev && (
            <PreviousButton
              onClick={scrollPrevious}
              $columns={columns}
              $hasContentCardMarginTop={hasContentCards}
              $isDark={isDark}
            >
              <ChevronDown />
            </PreviousButton>
          )}
          {showNext && (
            <NextButton
              onClick={scrollNext}
              $columns={columns}
              $hasContentCardMarginTop={hasContentCards}
              $isDark={isDark}
            >
              <ChevronDown />
            </NextButton>
          )}
        </>
      )}
    </Container>
  );
};

export default Carousel;
