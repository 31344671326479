import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { breakpointAndUp, colors, BreakpointShorthands, breakpointAndUpShorthands } from 'assets/styles';

export const Container = styled.div`
  position: relative;
  min-width: 0;
`;

export const SliderContainer = styled.div`
  // negative margins top and bottom to show hover effects
  margin: -1.4rem -2.4rem;
  padding: 1.4rem 2.4rem;

  overflow-x: hidden;

  @media (${breakpointAndUp.tablet}) {
    margin: -1.4rem 0;
    padding: 1.4rem 0;
  }
`;

export const Slider = styled.ul`
  display: flex;

  @media (${breakpointAndUp.tablet}) {
    padding: 0;
  }
`;

const slideGapMap = {
  [BreakpointShorthands.Mobile]: '1.2rem',
  [BreakpointShorthands.Tablet]: '1.2rem',
  [BreakpointShorthands.TabletLarge]: '1.6rem',
  [BreakpointShorthands.Desktop]: '1.6rem',
};

const parseSlideColumns = (sizes: string) =>
  sizes?.split(' ').map((col) => {
    const [breakpointShorthand, columns] = col.split(':');

    return css`
      @media (${breakpointAndUpShorthands[breakpointShorthand]}) {
        flex: 0 0
          calc((100% / ${columns}) - (${slideGapMap[breakpointShorthand]} / ${columns} * ${parseInt(columns) - 1}));
      }
    `;
  });

export const Slide = styled.li`
  ${({ $columns }) => parseSlideColumns($columns)};
  margin-right: 1.2rem;

  @media (${breakpointAndUp.tabletLarge}) {
    margin-right: 1.6rem;
  }
`;

const buttonMaxMarginTopMap = {
  [BreakpointShorthands.Mobile]: '15rem',
  [BreakpointShorthands.Tablet]: '15rem',
  [BreakpointShorthands.TabletLarge]: '8.2rem',
  [BreakpointShorthands.Desktop]: '10.4rem',
};

const parseButtonColumns = (sizes: string) =>
  sizes?.split(' ').map((col) => {
    const [breakpointShorthand, columns] = col.split(':');

    return css`
      @media (${breakpointAndUpShorthands[breakpointShorthand]}) {
        margin-top: min(
          ${buttonMaxMarginTopMap[breakpointShorthand]},
          calc(
            ((100% / ${columns}) - (${slideGapMap[breakpointShorthand]} / ${columns} * ${parseInt(columns) - 1})) / 2
          )
        );
      }
    `;
  });

const buttonStyle = css`
  display: none;
  position: absolute;
  top: ${({ $hasContentCardMarginTop }) => ($hasContentCardMarginTop ? 0 : '50%')};
  padding: 1.2rem;
  border-radius: 4rem;
  background-color: ${({ $isDark }) => rgba(colors.white, $isDark ? 0.7 : 1)};

  font-size: 2.4rem;
  color: ${({ $isDark }) => ($isDark ? colors.white : colors.text)};
  line-height: 62.5%;

  border: none;
  box-shadow: 0 0.4rem 2.8rem ${rgba(colors.black, 0.1)};
  cursor: pointer;

  @supports ((-webkit-backdrop-filter: blur(3.6rem)) or (backdrop-filter: blur(3.6rem))) {
    background-color: ${({ $isDark }) => $isDark && rgba(colors.white, 0.3)};
    backdrop-filter: ${({ $isDark }) => $isDark && 'blur(3.6rem)'};
  }

  @media (${breakpointAndUp.tablet}) {
    display: block;
  }

  ${({ $hasContentCardMarginTop, $columns }) => $hasContentCardMarginTop && parseButtonColumns($columns)};
`;

export const PreviousButton = styled.button`
  ${buttonStyle};
  left: 0;
  transform: translate(-50%, -50%) rotate(90deg);
`;

export const NextButton = styled.button`
  ${buttonStyle};
  right: 0;
  transform: translate(50%, -50%) rotate(-90deg);
`;
