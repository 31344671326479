import styled, { css } from 'styled-components';
import { breakpointAndUp, colors, typography } from 'assets/styles';

export const Container = styled.div`
  margin: 0 0 1.8rem;

  @media (${breakpointAndUp.tablet}) {
    margin: 0 0 2.4rem;
  }

  @media (${breakpointAndUp.tabletLarge}) {
    margin: 2.4rem 0 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
`;

export const SearchContainer = styled.div`
  position: relative;
  width: 100%;

  ${({ $fullWidth }) =>
    !$fullWidth &&
    css`
      @media (${breakpointAndUp.tabletLarge}) {
        max-width: 48.2rem;
      }

      @media (${breakpointAndUp.desktop}) {
        max-width: 65rem;
      }
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  margin-top: 1.2rem;

  color: ${({ $isDark }) => ($isDark ? colors.white : colors.text)};

  @media (${breakpointAndUp.tablet}) {
    margin-top: 2.2rem;
  }
`;

export const DesktopTitle = styled.h1`
  ${typography.h1};
  display: block;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const IconContainer = styled.span`
  font-size: 2.4rem;
  color: ${colors.tint};
  line-height: 62.5%;
  margin-right: 0.8rem;
`;

export const AccountContainer = styled.div`
  display: none;
  margin-left: 1.2rem;

  @media (${breakpointAndUp.tabletLarge}) {
    display: block;
  }
`;
