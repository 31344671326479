import React, { ComponentProps, useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import { useSelector } from 'react-redux';
import { Moment } from 'models/moment';
import { SessionSourceTypes } from 'models/session';
import { MusicCategory } from 'models/music-category';
import { Meditation } from 'models/meditation';
import { MusicTrack } from 'models/music-track';
import { Selectors as userSelectors } from 'domains/user';
import Grid from 'components/grid';
import Section from 'components/section';
import SectionHeader from 'components/section-header';
import Carousel from 'components/carousel';
import { Meditate, Music } from 'assets/icons';
import { Anchor } from './slider.style';

const ContentCard = dynamic(import('components/content-card'));
const CollectionCard = dynamic(import('components/collection-card'));

export type SliderItemTypes = Meditation | Moment | MusicCategory | MusicTrack;

type ItemProps = ComponentProps<'article'> & {
  item: SliderItemTypes;
  isDark?: boolean;
};

const Item: React.FC<ItemProps> = ({ item, isDark, onClick }) => {
  const user = useSelector(userSelectors.getUser);

  return (item as Meditation).releaseDate ? (
    <ContentCard
      image={(item as Meditation).image}
      title={(item as Meditation).title}
      label={(item as Meditation).label}
      metaLabel={(item as Meditation).metaLabel}
      typeIcon={(item as Meditation).type === 'meditation' ? <Meditate /> : <Music />}
      isPremium={!user?.premium && (item as Meditation).premium}
      onClick={onClick}
      isDark={isDark}
    />
  ) : (
    <CollectionCard
      image={isDark ? (item as Moment | MusicCategory).dark.image : (item as Moment | MusicCategory).light.image}
      title={item.title}
      onClick={onClick}
      isDark={isDark}
    />
  );
};

export type SliderProps = ComponentProps<'section'> & {
  title: string;
  items: SliderItemTypes[];
  source?: SessionSourceTypes;
  isDark?: boolean;
  isFirst?: boolean;
  moreLink?: string;
  moreLabel?: string;
  hideArrows?: boolean;
  columns?: string;
  onItem?: (uid: string) => void;
};

const Slider: React.FC<SliderProps> = ({
  title,
  items,
  onItem,
  source,
  isDark,
  isFirst,
  moreLink,
  moreLabel,
  columns,
  hideArrows,
}) => {
  const [isScrollable, setIsScrollable] = useState<boolean>(true);
  const onChangeScrollable = useCallback((scrollable: boolean) => setIsScrollable(scrollable), []);

  return (
    <Section isFirst={isFirst}>
      <Grid columns="mb:1">
        <SectionHeader
          isDark={isDark}
          title={title}
          link={isScrollable ? moreLink : undefined}
          linkLabel={isScrollable ? moreLabel : undefined}
        />
      </Grid>
      <Grid columns="mb:1">
        <Carousel
          isDark={isDark}
          columns={columns}
          hideArrows={hideArrows}
          hasContentCards={!!(items[0] as Meditation)?.releaseDate}
          onChangeScrollable={onChangeScrollable}
        >
          {items.map((item) => {
            const onClick = () => onItem?.(item.uid);

            return !(item as Meditation).url ? (
              <Item key={item.uid} item={item} isDark={isDark} onClick={onClick} />
            ) : (
              <NextLink legacyBehavior key={item.uid} href={(item as Meditation).url} passHref>
                <Anchor source={source}>
                  <Item item={item} isDark={isDark} />
                </Anchor>
              </NextLink>
            );
          })}
        </Carousel>
      </Grid>
    </Section>
  );
};

export default Slider;
