import { breakpointAndUpShorthands } from 'assets/styles';
import styled, { css } from 'styled-components';

export const Container = styled.section`
  margin: ${({ $isFirst }) => ($isFirst ? '2rem 0 3.2rem' : '3.2rem 0')};

  ${({ $hide }) =>
    Object.keys(breakpointAndUpShorthands).map(
      (shorthand) => css`
        @media (${breakpointAndUpShorthands[shorthand]}) {
          display: ${$hide.includes(shorthand) ? 'none' : 'block'};
        }
      `,
    )};
`;
