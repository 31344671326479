import React, { ComponentProps, useCallback, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { MenuItemPage } from 'models/menu-item';
import { LOCALES } from 'config/constants';
import { useIntl } from 'domains/intl';
import { Selectors as userSelectors } from 'domains/user';
import { useArgumentsLink } from 'domains/route';
import { useAppInfo } from 'logic-domains/app';
import { useTrackSearch } from 'logic-domains/tracking';
import { profilePopupLinks } from 'data/menus';
import Grid from 'components/grid';
import SearchButton from 'components/search-button';
import HeaderAccountButtons from 'components/header-account-buttons';
import {
  AccountContainer,
  Container,
  Content,
  DesktopTitle,
  IconContainer,
  SearchContainer,
  TitleContainer,
} from './page-header.style';

const Search = dynamic(import('layouts/search'));

export type PageHeaderProps = ComponentProps<'div'> & {
  title: string;
  icon?: JSX.Element;
  isDark?: boolean;
};

const PageHeader: React.FC<PageHeaderProps> = ({ title, icon, isDark }) => {
  const trackSearch = useTrackSearch();
  const { formatMessage } = useIntl();
  const [
    {
      headerCtaBtn,
      headerCtaTargetUrl,
      headerCtaTrialBtn,
      headerCtaTrialTargetUrl,
      commonLoginBtn,
      commonLoginTargetUrl,
      commonLogoutBtn,
      commonLogoutTargetUrl,
      commonManageSubscriptionUrl,
      commonSubscribeTargetUrl,
    },
  ] = useAppInfo();
  const subscribeLink = useArgumentsLink(commonLoginTargetUrl, true);
  const logoutLink = useArgumentsLink(commonLogoutTargetUrl, true);
  const tryLink = useArgumentsLink(headerCtaTargetUrl, true);
  const tryTrialLink = useArgumentsLink(headerCtaTrialTargetUrl, true);
  const user = useSelector(userSelectors.getUser);
  const profileLinks = useMemo(
    () =>
      profilePopupLinks.map((menuItem) => {
        let url: string | Record<LOCALES, string>;
        switch (menuItem.page) {
          case MenuItemPage.Subscription:
            url = commonManageSubscriptionUrl;
            break;
          case MenuItemPage.Subscribe:
            url = commonSubscribeTargetUrl;
            break;
          default:
            url = menuItem.url;
        }

        return { ...menuItem, label: formatMessage(menuItem.label), url };
      }),
    [commonManageSubscriptionUrl, formatMessage],
  );
  const [hasFocus, setHasFocus] = useState(false);
  const onFocusSearch = useCallback(() => {
    setHasFocus(true);
    trackSearch();
  }, []);
  const onUnFocusSearch = useCallback(() => setHasFocus(false), []);

  return (
    <Container>
      <Grid columns="mb:1">
        <Content>
          <SearchContainer $fullWidth={hasFocus}>
            <SearchButton isDark={isDark} label={formatMessage('search.placeholder')} onClick={onFocusSearch} />
            {hasFocus && <Search onClose={onUnFocusSearch} />}
          </SearchContainer>
          <AccountContainer>
            <HeaderAccountButtons
              user={user}
              isDark={isDark}
              loginLabel={commonLoginBtn}
              loginLink={subscribeLink}
              logoutLabel={commonLogoutBtn}
              logoutLink={logoutLink}
              profileLinks={profileLinks}
              tryLinkLabel={user?.hadTrial ? headerCtaBtn : headerCtaTrialBtn}
              tryLink={user?.hadTrial ? tryLink : tryTrialLink}
            />
          </AccountContainer>
        </Content>
        <TitleContainer $isDark={isDark}>
          {icon && <IconContainer>{icon}</IconContainer>}
          <DesktopTitle>{title}</DesktopTitle>
        </TitleContainer>
      </Grid>
    </Container>
  );
};

export default PageHeader;
