import { colors, typography } from 'assets/styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.4rem;

  color: ${({ $isDark }) => ($isDark ? colors.white : colors.text)};
`;

export const Title = styled.h1`
  ${typography.h3};
`;

export const Anchor = styled.a`
  ${typography.h5};
  color: ${colors.greyTwo};

  text-decoration: none;
`;
