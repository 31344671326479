import styled from 'styled-components';
import { rgba } from 'polished';
import { Search } from 'assets/icons';
import { colors, typography } from 'assets/styles';

export const Container = styled.button`
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 1.4rem 1.6rem;

  background-color: ${({ $isDark }) => ($isDark ? colors.darkGrey : colors.lightGrey)};
  border: 1px solid ${({ $isDark }) => rgba($isDark ? colors.darkGrey : colors.white, 0.3)};
  border-radius: 2.4rem;
  cursor: pointer;

  text-align: left;
  color: ${colors.greyTwo};
`;

export const SearchIcon = styled(Search)`
  font-size: 1.6rem;
  margin-right: 0.8rem;
`;

export const Label = styled.span`
  ${typography.h5};
`;
