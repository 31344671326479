import React, { ComponentProps } from 'react';
import { Container, SearchIcon, Label } from './search-button.style';

export type SearchButtonProps = ComponentProps<'button'> & {
  label: string;
  isDark?: boolean;
};

const SearchButton: React.FC<SearchButtonProps> = ({ label, isDark, ...rest }) => (
  <Container $isDark={isDark} {...rest}>
    <SearchIcon />
    <Label>{label}</Label>
  </Container>
);

export default SearchButton;
