import React, { ComponentProps } from 'react';
import NextLink from 'next/link';
import { Container, Title, Anchor } from './section-header.style';

export type SectionHeaderProps = ComponentProps<'div'> & {
  title: string;
  link?: string;
  linkLabel?: string;
  isDark?: boolean;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, link, linkLabel, isDark }) => (
  <Container $isDark={isDark}>
    <Title>{title}</Title>
    {link && linkLabel && (
      <NextLink legacyBehavior href={link} passHref>
        <Anchor>{linkLabel}</Anchor>
      </NextLink>
    )}
  </Container>
);

export default SectionHeader;
